<script setup lang="ts">
import type { MenuItem } from '@groveco/http-services'
import { SkyLink } from '@groveco/skylight'

type SkyLinkRef = InstanceType<typeof SkyLink> | null

const props = defineProps<MenuItem>()

const ALL_CATEGORIES = 'All Categories'

const expandedChild = ref<MenuItem | null>(null)
const expandedChildRefs = ref<Record<string, SkyLinkRef>>({})
const isActive = ref(false)
const root = ref<HTMLElement | null>(null)

/**
 * Find the first child with children (Level 3) that will be expanded by default.
 */
const childSelection = computed<MenuItem | undefined>(() =>
  props.children.find(({ children }) =>
    children.some(({ children }) => children.length)
  )
)

/**
 * Split expanded children into 3 columns.
 * The columns are calculated based on the total number of children.
 */
const expandedChildColumns = computed<MenuItem[][]>(() => {
  if (!expandedChild.value) {
    return []
  }

  // Menu items start with the child items of the current expanded child
  const menuItems = expandedChild.value.children

  // Calculate the total amount of menu items
  const totalItems = menuItems.reduce(
    (acc, { children }) => acc + children.length,
    menuItems.length
  )

  // Define the columns for the menu items and calculate the target size for each column
  // Number of columns needs to match the amount of _ChildList columns in CSS
  const menuItemColumns: MenuItem[][] = [[], [], [], []]
  const columnCount = menuItemColumns.length
  const targetSize = Math.ceil(totalItems / columnCount)

  let columnIndex = 0
  let columnSize = 0

  // Distribute the menu items into the columns
  for (const menuItem of menuItems) {
    menuItemColumns[columnIndex].push(menuItem)
    columnSize += menuItem.children.length + 1 // +1 for the parent item

    // Move to the next column if the current size exceeds or meets the target size
    if (columnIndex < columnCount - 1 && columnSize >= targetSize) {
      columnIndex++
      columnSize = 0
    }
  }

  return menuItemColumns
})

/**
 * Check on focus if the root element (this menu item) contains the active focused element.
 * If not, set isActive to false.
 */
const checkFocusIn = () => {
  isActive.value = root.value?.contains(document.activeElement) || false
  if (!isActive.value) {
    window.removeEventListener('focusin', checkFocusIn)
  }
}

/**
 * Expand a child menu item.
 * When changeFocus is enabled the focus will be moved to the first child of the expanded child.
 */
const expandChild = (child: MenuItem, changeFocus = false) => {
  expandedChild.value = child
  if (changeFocus) {
    nextTick(() => {
      expandedChildRefs.value[child.id]?.$el.focus()
    })
  }
}

/**
 * Open the menu and watch for focus changes.
 */
const handleOpenMenu = () => {
  isActive.value = true
  window.addEventListener('focusin', checkFocusIn)
}

/**
 * If menu list item is active and no child is active expand the first child with children.
 */
watch(isActive, () => {
  if (isActive.value && !expandedChild.value) {
    expandedChild.value = childSelection.value || props
  }
})

onUnmounted(() => {
  window.removeEventListener('focusin', checkFocusIn)
})
</script>

<template>
  <li
    ref="root"
    class="HeaderMenuListItem"
    @click="isActive = false"
    @keyup.esc="isActive = false"
    @mouseenter="isActive = true"
    @mouseleave="isActive = false"
  >
    <SkyLink
      data-test-id="menu-link"
      :to="href"
      class="HeaderMenuListItem_Link"
      dark
      @focusin="handleOpenMenu"
    >
      <SkyIcon v-if="text === ALL_CATEGORIES" name="menu" size="16px" />
      {{ text }}
    </SkyLink>
    <div
      v-if="isActive && children.length"
      data-test-id="flyout"
      class="HeaderMenuListItem_Flyout"
    >
      <div
        :class="{
          'HeaderMenuListItem_FlyoutContainer--selection': childSelection,
        }"
        class="HeaderMenuListItem_FlyoutContainer"
        @mouseleave="isActive = false"
      >
        <ul
          v-if="childSelection"
          class="HeaderMenuListItem_ChildList HeaderMenuListItem_ChildList--selection"
        >
          <li
            v-for="child in children"
            :key="child.id"
            class="HeaderMenuListItem_Child"
          >
            <SkyButton
              v-if="child.children.length"
              :aria-expanded="String(expandedChild === child)"
              data-test-id="expand-child"
              class="HeaderMenuListItem_FlyoutButton"
              block
              dark
              icon
              @click.stop="expandChild(child, true)"
              @mouseenter="expandChild(child)"
            >
              <SkyLink
                :to="child.href"
                dark
                data-test-id="flyout-link"
                class="HeaderMenuListItem_FlyoutLink"
              >
                {{ child.text }}
              </SkyLink>
              <SkyIcon aria-hidden="true" name="chevron-right" size="16px" />
            </SkyButton>
            <SkyLink
              v-else
              :to="child.href"
              data-test-id="flyout-link"
              class="HeaderMenuListItem_FlyoutLink"
              dark
              @mouseenter="expandedChild = null"
            >
              {{ child.text }}
            </SkyLink>
          </li>
        </ul>
        <ul
          class="HeaderMenuListItem_ChildList HeaderMenuListItem_ChildList--expanded"
        >
          <div
            v-for="(childList, listIndex) in expandedChildColumns"
            :key="listIndex"
            class="HeaderMenuListItem_ChildListColumn"
            data-test-id="flyout-column"
          >
            <li
              v-for="(child, childIndex) in childList"
              :key="child.id"
              class="HeaderMenuListItem_Child"
            >
              <SkyLink
                :ref="(el) => { if (childSelection && listIndex === 0 && childIndex === 0 && expandedChild?.id) { expandedChildRefs[expandedChild.id] = el as SkyLinkRef }}"
                :to="child.href"
                data-test-id="flyout-link"
                dark
              >
                {{ child.text }}
              </SkyLink>
              <ul
                v-if="child.children.length"
                class="HeaderMenuListItem_ChildList"
              >
                <li
                  v-for="nestedChild in child.children"
                  :key="nestedChild.id"
                  class="HeaderMenuListItem_Child HeaderMenuListItem_Child--secondary"
                >
                  <SkyLink
                    :to="nestedChild.href"
                    data-test-id="flyout-link"
                    dark
                  >
                    {{ nestedChild.text }}
                  </SkyLink>
                </li>
              </ul>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </li>
</template>

<style lang="scss">
.HeaderMenuListItem {
  list-style: none;
  padding: var(--spacing-3x) var(--spacing-3x) var(--spacing-2x);
  display: flex;
  height: 100%;
  white-space: nowrap;
  border-bottom: solid 2px transparent;
  transition: border-color 0.3s ease-out;
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease-out, ease-out, ease-out;
  transition-delay: 0s, 0s, 0s;
  transition-property: border-color;

  > .SkyLink,
  > .SkyButton {
    border-style: none;
  }

  &:hover,
  &:focus-within {
    border-bottom: solid 2px var(--text-color-action-secondary);
  }

  &_ChildList {
    padding: 0;
    margin: var(--spacing-2x) 0 0 0;
    display: grid;
    gap: var(--spacing-3x);

    &--expanded {
      grid-template-columns: repeat(4, 1fr);
      margin-top: var(--spacing-4x);
    }

    &--selection {
      background-color: var(--surface-color-default);

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0;
      margin-top: 0;
      padding-top: var(--spacing-2x);
    }
  }

  &_ChildListColumn {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8x);
  }

  &_Child {
    list-style: none;
    padding: 0;

    &--secondary {
      --font-family-default-medium: var(--font-family-default);
      --font-weight-medium: var(--font-weight-default);
      font-size: var(--font-size-75);
    }
  }

  &_Flyout {
    @include container-medium;
    --container-max: 1040px;

    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: calc(100vh - 100%); // Span the remaining viewport height
    background-color: rgba(0, 0, 0, 0.45);
    z-index: var(--z-index-dropdown);
  }

  &_FlyoutContainer {
    background-color: var(--surface-color-linen);
    height: fit-content;
    max-height: calc(100% - 60px);
    overflow-y: auto;

    display: grid;
    padding: 0 var(--spacing-6x) var(--spacing-4x);

    &--selection {
      padding: 0;
      gap: var(--spacing-4x);
      grid-template-columns: 25% 1fr;

      // Add back padding we removed from the parent list
      .HeaderMenuListItem_ChildList--expanded {
        padding-bottom: var(--spacing-4x);
      }
    }
  }

  &_FlyoutButton {
    text-align: left;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    padding-right: var(--spacing-3x);
    border-radius: 0;
    border-bottom: 2px solid transparent;
    overflow: hidden;

    .SkyLink {
      border-color: transparent;
    }

    &:hover,
    &:focus &:focus-within {
      background-color: var(--surface-color-linen);
      border-bottom-color: var(--text-color-action-dark-hover);
    }

    &[aria-expanded='true'] {
      background-color: var(--surface-color-linen);
    }

    &[aria-expanded='false'] {
      --font-family-default-medium: var(--font-family-default);
      --font-weight-medium: var(--font-weight-default);
    }
  }

  &_FlyoutLink {
    display: block;
    padding: var(--spacing-2x) var(--spacing-6x);
  }

  &_Link {
    display: flex;
    align-items: center;
    gap: var(--spacing-1x);
  }
}
</style>
